<template>
    <v-card hover>
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">관리자 추가</v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pb-0 pt-3">
            <v-row no-gutters>
                <v-col class="pt-5" style="font: normal normal bold 14px/25px Apple SD Gothic Neo;">일반관리자는 결제와 녹취 다운로드를 제외한 모든 기능을 이용할 수 있습니다.</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-5">
                    <v-list style="height:300px; overflow-y:auto; border:1px solid #F0F0F0; padding:5px;">
                        <v-list-item-group v-model="selectedItem">
                            <template v-for="(staffItem, staffIdx) in staffListSels">
                                <v-divider v-bind:key="'staff_item_div_' + staffItem.user_seqno" v-if="staffIdx > 0"></v-divider>
                                <v-list-item v-bind:key="'staff_item_' + staffItem.user_seqno" v-bind:value="staffItem">
                                    <v-list-item-content class="mt-3 mb-3">
                                        <v-list-item-title v-text="staffItem.user_name" style="font-size:1.4em; color:#555555;"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list-item v-if="staffListSels.length == 0" :value="null">
                                <v-list-item-content class="mt-3 mb-3">
                                    <v-list-item-title v-text="'선택 가능한 콜리가 없습니다'" style="font-size:1.4em; color:#555555;"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pb-5 pt-3">
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        outlined
                        hide-details
                        dense
                        solo
                        label="메일 주소를 입력해 주세요"
                        v-model="user_email"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon style="padding-right: 10px;">mdi-email</v-icon>
                        </template>
                        <template v-slot:append v-if="!checkEmail">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">메일주소가 올바르지 않습니다</div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-3 pb-6 pt-0 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">추가</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import adminApi from '@/api/admin'

export default {
    data: () => ({
        selectedItem: 0,
        regExpEmail: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
        user_email: ''
    }),
    computed: {
        ...mapGetters({
            adminUserId: 'login/getUserId',
            adminUserList: 'admin/getAdminUserList',
            staffList: 'svcStaff/staffList',
        }),
        checkEmail() {
            return !this.user_email ? true : this.user_email && this.regExpEmail.test(this.user_email)
        },
        staffListSels() {
            let ret = []
            let adminUserList = this.adminUserList

            if (this.staffList) {
                this.staffList.forEach(item => {
                    let bExist = false
                    if (adminUserList) {
                        adminUserList.forEach(adminItem => {
                            if (item.user_seqno == adminItem.user_seqno) {
                                bExist = true
                            }
                        })
                    }
                    if (bExist == false && item.device_type != 'LANDLINE') {
                        ret.push(item)
                    }
                })
            }
            return ret
        }
    },
    mounted() {
        this.$store.dispatch('svcStaff/getList', {
            scb: (c, va) => {
            }, fcb: (err, va) => {
            }, va: this,
        })
    },
    methods: {
        closePopup() {
            this.$emit('cancel')
        },
        submitPopup() {
            let item = this.selectedItem

            if (!item) {
                this.$store.dispatch('openAlert', {message: '일반 관리자로 등록할 콜리를 선택해 주세요'})
                return
            } else if (this.user_email && !this.checkEmail) {
                this.$store.dispatch('openAlert', {message: '메일 주소가 올바르지 않습니다'})
                return
            }

            let confirmMsg = this.user_email ? '추가 시 가입문자 및 메일이 자동발송되며, 안내에 따라 관리자로 가입해야 추가가 완료됩니다' : '추가 시 가입문자가 자동발송되며, 안내에 따라 관리자로 가입해야 추가가 완료됩니다'
            let alertMsg = this.user_email ? '추가 관리자에게 가입안내 문자 및 메일을 발송했습니다.' : '추가 관리자에게 가입안내 문자를 발송했습니다.'

            let va = this
            va.$store.dispatch('openConfirm', {
                message: item.user_name + '을 일반관리자로 추가하시겠습니까?',
                sub_message: confirmMsg,
                va: va,
                okCb: function (va) {
                    adminApi.addAdmin({
                        user_seqno: item.user_seqno,
                        user_email: va.user_email
                    }, (va) => {
                        // success 
                        va.$store.dispatch('openAlert', {
                            va: va,
                            message: alertMsg,
                            sub_message: '(※일부 알뜰폰은 문자가 수신되지 않을 수 있습니다.)',
                            closeCb: function (va) {
                                va.$emit('submit')
                            }
                        })
                    }, (err, va) => {
                        va.$store.dispatch('openAlert', {message: '일반 관리자로 등록을 실패 했습니다.'})
                    }, va)
                }
            })
        },
    }
}
</script>
